import { SearchableTask } from '@super-software-inc/foundation'
import { FlexRow, Tooltip } from 'components/lib'
import React from 'react'
import styled from 'styled-components/macro'
import { PiPathBold } from 'react-icons/pi'

export const EventText = styled.div`
  color: ${props => props.theme.colors.text250};
  font-size: 0.8rem;
`

interface HandoffReasonActivityProps {
  selectedTask?: SearchableTask
}

const HandoffReasonActivity = ({
  selectedTask,
  ...rest
}: HandoffReasonActivityProps) => {
  const handoffReason = selectedTask?.handoffReason

  return (
    <div {...rest}>
      <FlexRow align="flex-start" style={{ marginBottom: '1rem' }}>
        <EventText>
          <div>
            <Tooltip
              placement="bottomLeft"
              overlay={
                <span>{handoffReason || 'No handoff reason available'}</span>
              }
              maxWidth="300px"
            >
              <EventText style={{ display: 'inline-flex', cursor: 'help' }}>
                <PiPathBold
                  style={{
                    fontSize: 14,
                    color: '#8A94A6',
                    marginRight: 8,
                    marginTop: 1,
                    paddingTop: 2,
                    display: 'inline',
                  }}
                />
                <span style={{ textDecoration: 'underline' }}>
                  Handoff reason
                </span>
              </EventText>
            </Tooltip>
          </div>
        </EventText>
      </FlexRow>
    </div>
  )
}

export default HandoffReasonActivity
