import { ContactReference } from '@super-software-inc/foundation'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { ref, uploadBytes } from 'firebase/storage'
import { pick } from 'lodash'
import Papa from 'papaparse'
import * as Sentry from '@sentry/react'
import { toastError, toastSuccess } from 'components/lib/Toast'
import createUniqueFilename from 'utils/createUniqueFilename'
import { firestore, storage } from '../firebase/setup'

export interface CSVUploadConfig {
  path: string
  fields: string[]
  successMessage: string
  createdBy: ContactReference
  mode?: 'update' | 'create'
}

export const uploadCSV = (file: File, config: CSVUploadConfig): Promise<void> =>
  new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async results => {
        try {
          if (results.data.length === 0) {
            toastError('CSV file is empty.')
            reject(new Error('CSV file is empty'))
            return
          }

          const { mode, path, fields, createdBy, successMessage } = config
          const filesCollection = collection(firestore, path)
          const fileName = createUniqueFilename(file.name)
          const fileURL = `${path}/${fileName}`
          const newFileRef = ref(storage, fileURL)
          const fileWithUniqueName = new File([file], fileName)

          await uploadBytes(newFileRef, fileWithUniqueName, {
            contentType: file.type,
          })

          const csv = results.data.map(r => pick(r, fields))

          await addDoc(filesCollection, {
            csv,
            filename: fileName,
            type: file.type,
            size: file.size,
            createdAt: serverTimestamp(),
            createdBy,
            fileURL,
            ...(mode ? { mode } : {}),
          })

          toastSuccess(successMessage)
          resolve()
        } catch (e) {
          toastError(
            'Upload failed. Please check your CSV for empty columns and try again.',
          )
          Sentry.captureException(e)
          reject(e)
        }
      },
      error: error => {
        toastError('Error parsing CSV. Please try again.')
        Sentry.captureException(error)
        reject(error)
      },
    })
  })
