import NiceModal from '@ebay/nice-modal-react'
import { createContactReference } from '@super-software-inc/foundation'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import BaseCSVUploadModal from './BaseCSVUploadModal'

export const propertyHeaders = [
  'Name',
  'Building number',
  'Street address',
  'Slug',
  'Borough',
  'Block',
  'Lot',
]

export const PropertyCSVUploadModal = NiceModal.create(
  ({ companyId, companyName }: { companyId: string; companyName: string }) => {
    const authenticatedUser = useRecoilValue(authenticatedUserAtom)

    const config = {
      path: `companies/${companyId}/propertyCSVImports`,
      fields: propertyHeaders,
      successMessage:
        'CSV uploaded. Properties will be imported shortly and an email confirmation of results will be delivered to sales@hiresuper.com.',
      createdBy: createContactReference(authenticatedUser.selectedContact),
    }

    return (
      <BaseCSVUploadModal
        title={`Import properties to ${companyName}`}
        config={config}
      />
    )
  },
)

export default PropertyCSVUploadModal
