import NiceModal from '@ebay/nice-modal-react'
import { createContactReference } from '@super-software-inc/foundation'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { authenticatedUserAtom } from 'state/atoms'
import BaseCSVUploadModal from './BaseCSVUploadModal'
import { CSVUploadConfig } from '../../utils/uploadCSV'

interface UnitContactModalParams {
  companyId: string
  companyName: string
  mode: Required<CSVUploadConfig>['mode']
}

export const contactHeaders = [
  'Company Name',
  'Property Name',
  'Property ID',
  'External ID',
  'First name',
  'Last name',
  'Email',
  'Phone number',
  'Street address',
  'Unit number',
  'City',
  'State (2 letter abbv)',
  'Zip code',
  'Residence start date',
  'Residence end date',
  'Contact type',
  'Access level',
  'Phone: Landline',
  'Phone: Landline alternate',
  'Phone: Alternate cell',
  'Email: Alternate',
  'Vendor Company',
  'Board relationship',
  'Board start date',
  'Board end date',
]

export const UnitContactCSVUploadModal = NiceModal.create(
  ({ companyId, companyName, mode }: UnitContactModalParams) => {
    const authenticatedUser = useRecoilValue(authenticatedUserAtom)

    const config: CSVUploadConfig = {
      path: `companies/${companyId}/unitContactCsvImports`,
      fields: contactHeaders,
      successMessage:
        'CSV uploaded. Contacts will be imported shortly and an email confirmation of results will be delivered to sales@hiresuper.com.',
      createdBy: createContactReference(authenticatedUser.selectedContact),
      mode,
    }

    return (
      <BaseCSVUploadModal
        title={`Import contacts to ${companyName} for ${
          mode === 'update' ? 'update' : 'creation'
        }`}
        config={config}
      />
    )
  },
)

export default UnitContactCSVUploadModal
