import { APIDelivery, DeliveryStatusType } from '@super-software-inc/foundation'

export const getDeliveries = async (
  firebaseToken: string | undefined,
  companyId: string,
  personalOnly: boolean,
  statusFilter: DeliveryStatusType[],
  associationIds: string[],
) => {
  const backend = process.env.REACT_APP_APPENGINE_BACKEND_URL
  const url = `${backend}/deliveries/getDeliveries`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${firebaseToken}`,
    },
    body: JSON.stringify({
      companyId,
      personalOnly,
      statusFilter,
      associationIds,
    }),
  })
  const data = await response.json()
  return data as APIDelivery[]
}

export const getDelivery = async (companyId: string, deliveryId: string) => {
  const ans = {
    companyId: 1,
    deliveryId: 2,
  }
  return ans
}
