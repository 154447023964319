/* eslint-disable prefer-template */
import {
  APIContact,
  ContactGroup,
  getContactDisplayName,
} from '@super-software-inc/foundation'

import { getMentionsPlugin } from './plugin'
import { MentionChoice } from './types'
import renderSuggestions from './suggestions'

// Unit / home owners in the HOA
const OwnersGroup: MentionChoice = {
  type: 'group',
  label: 'owners',
  description: 'to mention all owners',
  id: 'owners',
}

// Board members of the HOA
const BoardGroup: MentionChoice = {
  type: 'group',
  label: 'board',
  description: 'to mention all board members',
  id: 'board',
}

// Property managers of the HOA
const PMGroup: MentionChoice = {
  type: 'group',
  label: 'management',
  description: 'to mention all property managers',
  id: 'management',
}

// Staff of the HOA
const StaffGroup: MentionChoice = {
  type: 'group',
  label: 'staff',
  description: 'to mention all staff',
  id: 'staff',
}
// Sponsors in the HOA directory
const SponsorGroup: MentionChoice = {
  type: 'group',
  label: 'sponsors',
  description: 'to mention all sponsors',
  id: 'sponsors',
}

// Renters in the HOA directory
const RenterGroup: MentionChoice = {
  type: 'group',
  label: 'renters',
  description: 'to mention all renters',
  id: 'renters',
}

// Residents in the HOA directory
const ResidentsGroup: MentionChoice = {
  type: 'group',
  label: 'residents',
  description: 'to mention all residents',
  id: 'residents',
}

/**
 * IMPORTANT: outer div's "suggestion-item-list" class is mandatory. The plugin uses this class for querying.
 * IMPORTANT: inner div's "suggestion-item" class is mandatory too for the same reasons
 */
const getMentionSuggestionsHTML = (items: MentionChoice[]) =>
  renderSuggestions(items)

/**
 * IMPORTANT: outer div's "suggestion-item-list" class is mandatory. The plugin uses this class for querying.
 * IMPORTANT: inner div's "suggestion-item" class is mandatory too for the same reasons
 */
const getTagSuggestionsHTML = (items: any[]) =>
  '<div class="suggestion-item-list">' +
  items.map(i => '<div class="suggestion-item">' + i.tag + '</div>').join('') +
  '</div>'

const groupHasContactWithNoEmailOrSMS = (
  groupName: ContactGroup,
  contacts: APIContact[] | undefined,
  associationId: string | undefined,
) => {
  const noContactMethod = contacts?.find(
    c =>
      c.propertyInfo
        .find(p => p.associationId === associationId)
        ?.groups.includes(groupName) &&
      (!c.email || c.email.length < 1) &&
      (!c.phone || c.phone.type !== 'mobile' || c.phone.number.length < 1),
  )
  return noContactMethod !== undefined
}

// Use a function to create unique instances of the plugin rather than a singleton.
const createMentionsPlugin = ({
  currentUserGroups,
  forceGroups,
}: {
  currentUserGroups: ContactGroup[]
  forceGroups?: boolean
}) =>
  getMentionsPlugin({
    getSuggestions: (
      type: string,
      text: string,
      associationId: string | undefined,
      contacts: APIContact[] | undefined,
      hasPMs: boolean | undefined,
      hasStaff: boolean | undefined,
      hasSponsors: boolean | undefined,
      hasRenters: boolean | undefined,
      hasResidents: boolean | undefined,
      hasOwners: boolean | undefined,
      hasBoard: boolean | undefined,
      done: Function,
    ) => {
      setTimeout(() => {
        if (type === 'mention') {
          // 1. Start choices with static groups.
          // 2. If the association has PMs, add the PM group.
          // 3. If the association has sponsors, add the sponsor group.

          const choices: MentionChoice[] = []
          if (hasOwners || forceGroups) {
            const owners = OwnersGroup
            if (forceGroups) {
              choices.push(owners)
            } else {
              owners.hasNoContactMethod = groupHasContactWithNoEmailOrSMS(
                owners.id as ContactGroup,
                contacts,
                associationId,
              )
              choices.push(owners)
            }
          }

          if (hasBoard || forceGroups) {
            const board = BoardGroup
            if (forceGroups) {
              choices.push(board)
            } else {
              board.hasNoContactMethod = groupHasContactWithNoEmailOrSMS(
                board.id as ContactGroup,
                contacts,
                associationId,
              )
              choices.push(board)
            }
          }

          if (hasPMs || forceGroups) {
            const pm = PMGroup
            if (forceGroups) {
              choices.push(pm)
            } else {
              pm.hasNoContactMethod = groupHasContactWithNoEmailOrSMS(
                pm.id as ContactGroup,
                contacts,
                associationId,
              )
              choices.push(pm)
            }
          }

          if (hasStaff || forceGroups) {
            const staff = StaffGroup
            if (forceGroups) {
              choices.push(staff)
            } else {
              staff.hasNoContactMethod = groupHasContactWithNoEmailOrSMS(
                staff.id as ContactGroup,
                contacts,
                associationId,
              )
              choices.push(staff)
            }
          }

          if (hasSponsors || forceGroups) {
            const sponsors = SponsorGroup
            if (forceGroups) {
              choices.push(sponsors)
            } else {
              sponsors.hasNoContactMethod = groupHasContactWithNoEmailOrSMS(
                sponsors.id as ContactGroup,
                contacts,
                associationId,
              )
              choices.push(sponsors)
            }
          }

          // If the user is in a group that can see renters or residents, add those groups
          if (
            forceGroups ||
            [
              ContactGroup.Management,
              ContactGroup.Sponsors,
              ContactGroup.Board,
            ].some(group => currentUserGroups.includes(group))
          ) {
            const renters = RenterGroup
            const residents = ResidentsGroup
            if (forceGroups) {
              choices.push(renters)
              choices.push(residents)
            } else {
              if (hasRenters) {
                renters.hasNoContactMethod = groupHasContactWithNoEmailOrSMS(
                  renters.id as ContactGroup,
                  contacts,
                  associationId,
                )
                choices.push(renters)
              }

              if (hasResidents) {
                residents.hasNoContactMethod = groupHasContactWithNoEmailOrSMS(
                  residents.id as ContactGroup,
                  contacts,
                  associationId,
                )
                choices.push(residents)
              }
            }
          }

          if (contacts) {
            choices.push(
              ...(contacts || []).map(
                c =>
                  ({
                    type: 'contact',
                    id: c.id,
                    label: getContactDisplayName(c),
                    photoURL: c.photoURL,
                    hasNoContactMethod:
                      (!c.email || c.email?.length < 1) &&
                      (!c.phone ||
                        c.phone?.type !== 'mobile' ||
                        c.phone?.number?.length < 1),
                    groups:
                      c.propertyInfo.find(
                        p => p.associationId === associationId,
                      )?.groups || [],
                  } as MentionChoice),
              ),
            )
          }

          const filteredChoices = choices
            .filter(i => i.label?.toLowerCase().includes(text.toLowerCase()))
            .slice(0, 10)

          done(filteredChoices)
        } else {
          // Tag suggestions not active.
          // done([{ tag: 'WikiLeaks' }, { tag: 'NetNeutrality' }])
          done([])
        }
      }, 0)
    },
    getSuggestionsHTML: (items: MentionChoice[], type: string) => {
      if (type === 'mention') {
        const html = getMentionSuggestionsHTML(items)
        return html
      }

      if (type === 'tag') {
        return getTagSuggestionsHTML(items)
      }

      return undefined
    },
  })

export default createMentionsPlugin
