import {
  APIContact,
  Comment,
  createContactReference,
  TaskSubscriber,
  APIComment,
  SearchableTask,
} from '@super-software-inc/foundation'
import { toastWarning } from 'components/lib/Toast'
import { uniqBy } from 'lodash'
import { updateTask } from 'api/tasks'
import { CommentOrigin } from '@super-software-inc/foundation/dist/types/Task'

// eslint-disable-next-line import/prefer-default-export
export const createComment = async ({
  companyId,
  contact,
  task,
  values,
  mentionSubscriptions,
}: {
  companyId: string
  contact: APIContact
  task: SearchableTask
  values: Omit<Comment, 'author'>
  mentionSubscriptions: TaskSubscriber[]
}) => {
  const contactsWithNoEmailOrMobile = mentionSubscriptions.filter(
    c =>
      (!c.email || c.email.length < 1) &&
      (!c.phone || c.phone.type !== 'mobile' || c.phone.number.length < 1),
  )
  if (contactsWithNoEmailOrMobile.length > 0) {
    toastWarning(
      mentionSubscriptions.length === 1
        ? 'This contact cannot be notified because they do not have contact info on file.'
        : 'Some contacts cannot be notified because they do not have contact info on file.',
    )
  }

  const userContactReference = createContactReference(
    contact,
    task.associationId || undefined,
  )

  // Only send a single comment with the update request
  const comments: APIComment[] = [
    {
      ...values,
      author: userContactReference,
      createdAt: Number(new Date()),
      read: true,
      origin: CommentOrigin.WEB,
    },
  ]

  const preference =
    userContactReference.email && userContactReference.email.length > 0
      ? 'email'
      : 'phone'

  return updateTask(companyId, task.associationId, task.id, {
    comments,
    subscriptions: uniqBy(
      [
        ...(task.subscriptions || []),
        { ...userContactReference, preferences: [preference] },
        ...(mentionSubscriptions || []),
      ],
      'contactId',
    ),
    modifiedBy: userContactReference,
  })
}
