import {
  Action,
  isAllowed,
  ResourceType,
  Feature,
  Category,
  CheckType,
} from '@super-software-inc/foundation'
import { addNewCategory } from 'api/categories/index'
import {
  Divider,
  FlexRow,
  LoadingIndicator,
  Modal,
  MultilevelItem,
  MultilevelNoResults,
  PrimaryButton,
  TruncatedText,
} from 'components/lib'
import StyledSelectContainer from 'components/lib/MaterialElements/StyledSelect'
import { uniqBy } from 'lodash'
import { companyTaskCategoriesAtom } from 'pages/Tasks'
import React, { useEffect, useMemo, useState } from 'react'
import { MdAdd, MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { useFirestore } from 'reactfire'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { useTheme } from 'styled-components'
import styled from 'styled-components/macro'
import { CompanySettingsTabs } from 'pages/Settings/CompanySettings/CompanySettings'
import { authenticatedUserAtom, routingStateAtom } from 'state/atoms'
import SelectSearchBar from 'components/lib/MaterialElements/SelectSearchBar'
import { KeyboardArrowDown } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import TagView, { TagViewPill } from './TagView'

interface TagSelectorProps {
  value?: string[]
  onChange: Function
  rest?: any
  style?: any
  isDisabled?: boolean
  isTaskSheet?: boolean
}

const Container = styled.div`
  position: relative;
  z-index: 1000;
`

const TagSelector = ({
  value,
  onChange,
  isDisabled = false,
  isTaskSheet = true,
  ...rest
}: TagSelectorProps) => {
  const navigate = useNavigate()
  const [companyTaskCategories, setCompanyTaskCategories] = useRecoilState(
    companyTaskCategoriesAtom,
  )

  const [searchValue, setSearchValue] = useState('')
  const [errorMessage, setErrorMessage] = useState({
    title: '',
    message: '',
  })
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const { selectedContact, selectedCompany, acts } = authenticatedUser
  const theme = useTheme()
  const firestore = useFirestore()

  const [categories, setCategories] = useState<Category[]>([])

  const canEditCategories = useMemo(
    () =>
      isAllowed(
        selectedContact,
        acts,
        selectedContact.associationIds,
        ResourceType.PROPERTY,
        Feature.CATEGORIES,
        Action.EDIT,
        CheckType.ANY,
      ),
    [selectedContact, acts],
  )

  // save the original values so the order doesn't update in real time.
  // category's that are selected are moved to the top when opening dropdown, and as you search
  const [, setOriginalValues] = useState<string[]>()
  const updateOrder = () => {
    setOriginalValues(value)
  }

  useEffect(() => {
    // update the order when searching
    updateOrder()
    // eslint-disable-next-line
  }, [searchValue])

  useEffect(() => {
    const sortedCategories = uniqBy(companyTaskCategories, 'name')
    setCategories(sortedCategories)
  }, [selectedCompany, firestore, companyTaskCategories])

  const hasTag = (tags: string[] | undefined, tagValue: string) => {
    if (!tags || !tags.length) {
      return false
    }

    const index = tags.findIndex(t => t === tagValue)
    if (index >= 0) {
      return true
    }
    return false
  }

  const toggleValue = (tag: Category) => {
    const { id } = tag
    if (!value || !value.length) {
      onChange([id])
    } else if (hasTag(value, id)) {
      const newValue = value.filter(entry => entry !== id)
      onChange(newValue)
    } else {
      onChange([...value, id])
    }
  }

  const addCategory = async (label: string) => {
    const structuredLabel =
      label.trim().charAt(0).toUpperCase() + label.trim().slice(1).toLowerCase()
    // TODO - validate data before save
    if (label.length < 1) {
      setErrorMessage({
        title: 'Please enter a value',
        message: 'You can’t save an empty category name.',
      })
    } else {
      const newlyAddedCategory = await addNewCategory(
        `${selectedCompany.id}`,
        'companyTaskCategories',
        structuredLabel,
        theme.colors.text100,
      )
      // automatically show new category as selected in dropdown
      toggleValue(newlyAddedCategory as Category)

      setCompanyTaskCategories([
        ...companyTaskCategories,
        {
          id: newlyAddedCategory.id,
          name: structuredLabel,
          color: theme.colors.text100,
        },
      ])
    }

    // reset the search field
    setSearchValue('')
  }

  const setRoutingState = useSetRecoilState(routingStateAtom)

  if (!companyTaskCategories) {
    return <LoadingIndicator />
  }

  return (
    <Container
      onClick={(e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <StyledSelectContainer
        disabled={isDisabled}
        defaultValue={value || []}
        value={value || []}
        placeholder={isTaskSheet ? 'Categories' : 'Add category'}
        onClose={(e?: React.MouseEvent) => {
          if (e) {
            e.preventDefault()
            e.stopPropagation()
          }
          setSearchValue('')
          const sortedCategories = [...categories].sort((a, b) => {
            const aSelected = value?.includes(a.id) ? -1 : 1
            const bSelected = value?.includes(b.id) ? -1 : 1
            if (aSelected !== bSelected) return aSelected - bSelected
            return a.name.localeCompare(b.name)
          })
          setCategories(sortedCategories)
        }}
        renderValue={() =>
          isTaskSheet ? (
            <TagView
              tags={companyTaskCategories.filter(tag =>
                value?.includes(tag.id),
              )}
            />
          ) : value && value.length > 0 ? (
            <FlexRow justify="space-between" align="center">
              {value && value.length > 0 ? (
                <TruncatedText
                  style={{
                    color: theme.colors.text200,
                  }}
                >
                  {value.length > 1
                    ? `${value.length} categories`
                    : companyTaskCategories
                        .filter(tag => value?.includes(tag.id))
                        .map(tag => tag.name)
                        .join(', ')}
                </TruncatedText>
              ) : (
                <span style={{ color: '#8A94A6', fontWeight: 300 }}>
                  Select categories
                </span>
              )}
            </FlexRow>
          ) : (
            <span style={{ color: '#8A94A6', fontWeight: 300 }}>
              Select categories
            </span>
          )
        }
        icon={!isTaskSheet ? KeyboardArrowDown : undefined}
        selectStyle={isTaskSheet ? 'pill' : 'rectangle'}
        fixedFooter={
          (canEditCategories || !isTaskSheet) && (
            <div style={{ height: '40px', margin: '-8px' }}>
              <MultilevelItem
                key="categories"
                onClick={() => {
                  setRoutingState({
                    settingsTab: 0,
                    companySettingsTab: CompanySettingsTabs.CATEGORIES,
                  })
                  navigate('/settings')
                }}
              >
                <FlexRow align="center" justify="center">
                  <p style={{ margin: 0 }}>Manage Categories</p>
                </FlexRow>
              </MultilevelItem>
            </div>
          )
        }
      >
        <SelectSearchBar
          setSearchText={setSearchValue}
          placeholder="Add category"
        />
        {categories
          .filter(option =>
            option.name.toLowerCase().includes(searchValue.toLowerCase()),
          )
          .map(option => (
            <MultilevelItem
              key={option.id}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                toggleValue(option)
              }}
            >
              <FlexRow
                align="center"
                style={{
                  marginTop: '-4px',
                  marginBottom: '-4px',
                  width: 250,
                }}
              >
                {hasTag(value, option.id) ? (
                  <MdCheckBox
                    style={{
                      fontSize: 18,
                      marginRight: 5,
                      marginTop: 1,
                    }}
                  />
                ) : (
                  <MdCheckBoxOutlineBlank
                    style={{
                      fontSize: 18,
                      color: '#C9CED6',
                      marginRight: 5,
                      marginTop: 1,
                    }}
                  />
                )}
                <TagViewPill
                  tags={[option]}
                  style={{ color: option.color || theme.colors.text100 }}
                />
              </FlexRow>
            </MultilevelItem>
          ))}
        {companyTaskCategories.filter(option =>
          option.name.toLowerCase().includes(searchValue.toLowerCase()),
        ).length < 1 && <MultilevelNoResults />}
        {searchValue.length > 0 && (!isTaskSheet || canEditCategories) && (
          <>
            <Divider style={{ marginBottom: 10 }} />
            <MultilevelItem
              key={selectedCompany.id}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                addCategory(searchValue)
              }}
            >
              <FlexRow align="center">
                <MdAdd style={{ fontSize: 20, marginRight: 15 }} />
                Add category{' '}
                <span
                  style={{ fontWeight: 600, marginRight: 5, marginLeft: 5 }}
                >
                  {' "'}
                  {searchValue}
                  {'" '}
                </span>
              </FlexRow>
            </MultilevelItem>
          </>
        )}
      </StyledSelectContainer>
      {errorMessage && (
        <Modal
          isOpen={errorMessage.title.length > 0}
          onRequestClose={() => {
            setErrorMessage({
              title: '',
              message: '',
            })
          }}
          size="sm"
          height="unset"
        >
          <h2>{errorMessage.title}</h2>
          <p style={{ marginTop: 10, marginBottom: 20 }}>
            {errorMessage.message}
          </p>
          <FlexRow justify="flex-end">
            <PrimaryButton
              onClick={() =>
                setErrorMessage({
                  title: '',
                  message: '',
                })
              }
            >
              Okay
            </PrimaryButton>
          </FlexRow>
        </Modal>
      )}
    </Container>
  )
}

export default TagSelector
