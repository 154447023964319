import { APIAssociation, APIContact } from '@super-software-inc/foundation'
import { updateContact } from 'api/contacts'
import { FlexRow, PrimaryButton, RadioButton } from 'components/lib'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { Field, Form, Formik } from 'formik'
import React, { SyntheticEvent } from 'react'
import { useRecoilValue } from 'recoil'
import { useTheme } from 'styled-components'
import * as Yup from 'yup'
import { windowDimensionsAtom } from '../../AppRoutes'
import {
  InputGroup,
  InputGroupLabel,
} from '../../components/app/ContactForm/ProfileForm'

const ContactNotificationSettingsFormSchema = Yup.object().shape({
  frequency: Yup.string(),
  associationIds: Yup.array().of(Yup.string()),
})

const ContactNotificationSettingsForm = ({
  contact,
  propertyOptions,
  closeModal,
}: {
  contact: APIContact
  propertyOptions?: APIAssociation[]
  closeModal: (contact: APIContact) => void
}) => {
  const theme = useTheme()

  const windowDimensions = useRecoilValue(windowDimensionsAtom)

  return (
    <Formik
      initialValues={{
        associationIds: contact.associationIds || [],
        frequency: contact.notificationSettings?.frequency || 'activity',
      }}
      validationSchema={ContactNotificationSettingsFormSchema}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          const updatedContact = await updateContact({
            ...contact,
            notificationSettings: {
              ...contact.notificationSettings,
              frequency: values.frequency,
            },
            associationIds: values.associationIds,
          })

          setSubmitting(false)
          toastSuccess('Contact updated.')
          resetForm({ values })
          closeModal(updatedContact)
        } catch (err) {
          // @ts-expect-error - HttpsErrors contain a message property
          if (err?.message) {
            toastError(
              <div>
                {/* @ts-expect-error - HttpsErrors contains a message property */}
                <div style={{ fontWeight: 'bold' }}>{err.message}</div>
              </div>,
            )
          } else {
            toastError(
              'An error occurred. Please check your inputs and try again.',
            )
          }
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form
          style={{
            width: windowDimensions.isMobile
              ? windowDimensions.width * 0.95
              : 600,
          }}
        >
          <InputGroup>
            <InputGroupLabel>Notification settings</InputGroupLabel>
            <FlexRow style={{ marginBottom: 8 }}>
              <Field
                value="activity"
                comparator={values.frequency}
                name="frequency"
                label="Enabled"
                as={RadioButton}
              />
            </FlexRow>
            <FlexRow style={{ marginBottom: 8 }}>
              <Field
                value="disabled"
                comparator={values.frequency}
                name="frequency"
                label="Disabled"
                as={RadioButton}
              />
            </FlexRow>
          </InputGroup>

          {propertyOptions && propertyOptions.length > 0 && (
            <InputGroup>
              <InputGroupLabel>Assigned properties</InputGroupLabel>
              <FlexRow justify="space-between">
                <Field
                  component="select"
                  name="associationIds"
                  multiple
                  style={{
                    width: '100%',
                    height: 217,
                    border: `1px solid ${theme.colors.bg300}`,
                  }}
                  disabled={
                    !contact.propertyInfo.some(p => p.propertyRelation !== null)
                  }
                  onChange={(evt: SyntheticEvent) => {
                    const { value } = evt.target as HTMLSelectElement

                    if (values.associationIds?.indexOf(value) === -1) {
                      setFieldValue('associationIds', [
                        ...values.associationIds,
                        value,
                      ])
                    } else {
                      setFieldValue(
                        'associationIds',
                        values.associationIds.filter(
                          (id: string) => id !== value,
                        ),
                      )
                    }
                  }}
                >
                  {propertyOptions.map(p => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                </Field>
              </FlexRow>
            </InputGroup>
          )}

          <PrimaryButton type="submit" disabled={isSubmitting}>
            Save changes
          </PrimaryButton>
        </Form>
      )}
    </Formik>
  )
}

export default ContactNotificationSettingsForm
