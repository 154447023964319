import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { useModal } from '@ebay/nice-modal-react'
import { Modal, FlexRow, IconButton, PrimaryButton } from 'components/lib'
import { MdClose } from 'react-icons/md'
import FileView from 'components/app/FileManager/FileView'
import UploadZone from 'components/app/FileManager/UploadZone'
import { toastError } from 'components/lib/Toast'
import { windowDimensionsAtom } from '../../AppRoutes'
import { uploadCSV, CSVUploadConfig } from '../../utils/uploadCSV'

interface BaseCSVUploadModalProps {
  title: string
  config: CSVUploadConfig
}

const BaseCSVUploadModal: React.FC<BaseCSVUploadModalProps> = ({
  title,
  config,
}) => {
  const modal = useModal()
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const [files, setFiles] = useState<File[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  return (
    <Modal
      isOpen={modal.visible}
      onRequestClose={() => {
        modal.reject('cancel')
        modal.hide()
      }}
      onAfterClose={() => modal.remove()}
      shouldCloseOnOverlayClick={false}
    >
      <div
        style={{
          width: windowDimensions.isMobile
            ? windowDimensions.width * 0.95
            : 600,
        }}
      >
        <FlexRow
          align="center"
          justify="space-between"
          style={{ marginBottom: '8px' }}
        >
          <h3 style={{ marginTop: 0 }}>{title}</h3>
          <IconButton
            onClick={() => modal.hide()}
            style={{ marginLeft: 'auto' }}
          >
            <MdClose />
          </IconButton>
        </FlexRow>

        <UploadZone
          onDropAccepted={(acceptedFiles: File[]) => {
            setFiles(acceptedFiles)
          }}
          accept=".csv"
          fileTypeNames="CSV"
        />

        {files.length > 0 &&
          files.map((file: File) => (
            <FileView
              key={file.name}
              file={file}
              onRemove={() => setFiles(files.filter(f => f.name !== file.name))}
            />
          ))}

        <FlexRow>
          <PrimaryButton
            type="button"
            disabled={isSubmitting}
            onClick={async () => {
              setIsSubmitting(true)

              const csv = files[0]
              if (!csv || csv.type !== 'text/csv') {
                toastError('Please upload a CSV file.')
                setIsSubmitting(false)
                return
              }

              try {
                await uploadCSV(csv, config)
                modal.resolve('confirm')
                modal.hide()
              } catch (error) {
                // Errors are handled in uploadCSV
              } finally {
                setIsSubmitting(false)
              }
            }}
          >
            Upload CSV
          </PrimaryButton>
        </FlexRow>
      </div>
    </Modal>
  )
}

export default BaseCSVUploadModal
