import React, { useEffect, useState } from 'react'
import { SearchableTask } from '@super-software-inc/foundation'
import { MdClose } from 'react-icons/md'
import { formatRelative } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { FlexRow, IconButton, Tooltip } from 'components/lib'
import { createChatCompletion } from 'api/openai'
import { useTheme } from 'styled-components'
import { HiMiniSparkles } from 'react-icons/hi2'
import BetaPill from 'components/lib/BetaPill'

const getRelativeDateStr = (dateStr: string) => {
  const formatRelativeLocale = {
    lastWeek: "'Last' EEEE",
    yesterday: "'Yesterday'",
    today: "'Today'",
    tomorrow: "'Tomorrow'",
    nextWeek: "'Next' EEEE",
    other: 'MM/dd/yyyy',
  }

  return formatRelative(new Date(dateStr), new Date(), {
    locale: {
      ...enUS,
      formatRelative: (token: string) =>
        formatRelativeLocale[token as keyof typeof formatRelativeLocale],
    },
  })
}

const SuggestDueDate = ({
  selectedTask,
  // setSelectedTask,
  onChange,
  setSuggestedDateString,
}: {
  selectedTask: SearchableTask
  // setSelectedTask: Function
  onChange: Function
  setSuggestedDateString: Function
}) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [suggestedDateStr, setSuggestedDateStr] = useState<string>()
  const [reason, setReason] = useState<string>()
  const [submitted, setSubmitted] = useState<boolean>(false)
  const theme = useTheme()

  useEffect(() => {
    if (
      !selectedTask ||
      selectedTask?.dueDate ||
      selectedTask.associationId === null
    ) {
      return
    }

    setVisible(true)
    setSuggestedDateString(undefined)
    setSuggestedDateStr(undefined)
    setReason(undefined)

    const doDueDatePrediction = async () => {
      const today = new Date().toISOString().split('T')[0]

      // Do summary request here
      const prompt = `
      Given today's date, the name of a task to be completed and its description, I want you to predict the likely due date for the task.

      For example, if today's date is "2020-01-04" and the task is "Water the plants on Monday" then I want you to predict the correct due date of the following Monday, "2020-01-06T00:00:00".

      The tasks will likely be regarding a building (or an apartment within a building) in New York City. Many tasks may concern reminders for filing compliance paperwork, paying bills, or other tasks that are required to be completed by a certain date. If so, cross-check the nature of the task content with the NYC Department of Buildings website to ensure that the due date is correct (in the context of the current year).
      
      Prefer using NYC Department of Buildings website for due date information. Only suggest IRS due dates if the task is clearly related to taxes. If the task is related to taxes, then use the IRS website for due date information.

      Output your response in JSON format with a two keys, "predictedDueDate" and "reason". The first key should be the due date prediction date string, in the format of YYYY-MM-DDT00:00:00. The second key should be a string that explains the reason for the prediction, or lack of it. If you are not confident of the due date, then simply respond with "predictedDueDate" being an empty string, and populate "reason" with an appropriate reason, if you are able to.
      
      Today's date: ${today}
      Current year: ${new Date().getFullYear()}
      Task title: ${selectedTask.title}
      Task description: ${selectedTask.description}
      `

      const res = await createChatCompletion({
        messages: [
          {
            role: 'user',
            content: prompt,
          },
        ],
      })

      // If no content, return:
      if (!res.completionMessage.content) {
        return
      }

      let parsedRes: {
        predictedDueDate?: string
        reason: string
      }

      try {
        parsedRes = JSON.parse(res.completionMessage.content)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e)

        return
      }

      setReason(parsedRes.reason)

      // If indeterminable, return:
      if (!parsedRes.predictedDueDate) {
        return
      }

      // If response not parsable, return:
      const parsedDate = new Date(parsedRes.predictedDueDate)

      if (Number.isNaN(parsedDate.getTime())) {
        return
      }

      // Otherwise set the suggested date string:
      setSuggestedDateString(parsedRes.predictedDueDate)
      setSuggestedDateStr(parsedRes.predictedDueDate)
    }

    doDueDatePrediction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask.id, selectedTask.dueDate])

  if (!visible) {
    return null
  }

  if (selectedTask.dueDate || !suggestedDateStr) {
    return null
  }

  return (
    <Tooltip overlay={<div style={{ maxWidth: 250 }}>{reason}</div>}>
      <div>
        <FlexRow
          justify="flex-start"
          align="center"
          style={{
            border: `dashed 1px ${theme.colors.secondaryPurple100}`,
            backgroundColor: theme.colors.secondaryPurple25,
            padding: '0px 8px 0px 0px',
            borderRadius: 30,
          }}
        >
          <IconButton
            type="button"
            disabled={submitted}
            onClick={async () => {
              setSubmitted(true)
              onChange(suggestedDateStr.split('T')[0])
              setSubmitted(false)
              setSuggestedDateString(undefined)
            }}
            style={{
              fontSize: 12,
              color: '#000',
              padding: '3px 9px 3px 14px',
            }}
          >
            <FlexRow align="center">
              {' '}
              <HiMiniSparkles
                style={{
                  marginRight: 4,
                  color: theme.colors.secondaryPurple700,
                }}
              />
              <p
                style={{
                  fontSize: 12,
                  color: theme.colors.secondaryPurple700,
                  fontWeight: '500',
                }}
              >
                Suggestion: Due {getRelativeDateStr(suggestedDateStr)}
              </p>
              <BetaPill style={{ marginLeft: 5 }}>BETA</BetaPill>
            </FlexRow>
          </IconButton>
          <IconButton
            onClick={() => {
              setSuggestedDateString(undefined)
              setVisible(false)
            }}
          >
            <MdClose style={{ marginLeft: 'auto', fontSize: 12 }} />
          </IconButton>
        </FlexRow>
      </div>
    </Tooltip>
  )
}

export default SuggestDueDate
