import {
  AccessLevel,
  APIRule,
  Comment,
  File,
  getContactDisplayName,
  SearchableTask,
  TaskActivityEvent,
  TaskOrigin,
} from '@super-software-inc/foundation'
import { FlexRow, SubtleLink, Tooltip } from 'components/lib'
import {
  differenceInSeconds,
  format,
  formatDistanceToNowStrict,
} from 'date-fns'
import {
  collection,
  DocumentData,
  DocumentReference,
  orderBy,
  query,
} from 'firebase/firestore'
import { startCase } from 'lodash'
import React, { useMemo, useRef } from 'react'
import { HiMiniSparkles } from 'react-icons/hi2'
import { Link, useNavigate } from 'react-router-dom'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CompanySettingsTabs } from 'pages/Settings/CompanySettings/CompanySettings'
import {
  authenticatedUserAtom,
  editingRuleAtom,
  routingStateAtom,
} from 'state/atoms'
import styled, { useTheme } from 'styled-components/macro'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import ContactAvatar from '../ContactAvatar'
import Editor from '../Editor/Editor'
import PollView from '../PollView/PollView'
import AudioPlayer from './AudioPlayer'

type ActivityType = 'event' | 'comment'

interface TaskActivityCombination {
  type: ActivityType
  data: Comment | TaskActivityEvent
  timestamp: Date
  index?: number
}

const describeEvent = (event: TaskActivityEvent, onClickRule?: Function) => {
  // New: use event "modifications" if available to better describe specific events like urgency changes.
  if (
    event.event === 'updated' &&
    event.modifications &&
    event.modifications.isUrgent
  ) {
    return `changed to ${
      event.modifications.isUrgent.after === true ? 'urgent' : 'not urgent'
    }`
  }

  if (
    event.event === 'updated' &&
    event.modifications &&
    event.modifications.status
  ) {
    return `changed status to ${event.modifications.status.after}`
  }

  if (
    event.event === 'updated' &&
    event.modifications &&
    event.modifications.associationId
  ) {
    return `changed property from ${event.modifications.associationId.before} to ${event.modifications.associationId.after}`
  }

  if (
    event.event === 'updated' &&
    event.modifications &&
    event.modifications.workspace
  ) {
    return `changed workspace to ${
      (event.modifications.workspace.after as string)?.replace('-', ' ') ??
      'general'
    }`
  }

  switch (event.event) {
    case 'created':
      if (event.origin === TaskOrigin.VOICE_MOBILE) {
        if (event.by?.firstName && event.by.phone?.number) {
          return `created this task via mobile ${formatPhoneNumber(
            event.by.phone.number,
          )}`
        }

        return `created this task via mobile`
      }

      if (event.origin === TaskOrigin.VOICE_LANDLINE) {
        if (event.by?.firstName && event.by.phone?.number) {
          return `created this task via landline ${formatPhoneNumber(
            event.by.phone.number,
          )}`
        }

        return `created this task via landline`
      }

      return `created this task`
    case 'duplicated':
      return (
        <span style={{ paddingLeft: 4 }}>
          {' '}
          duplicated this task from{' '}
          <Link to={`/tasks/${event.modifications?.id.before}`}>
            {event.modifications?.title.before}
          </Link>
        </span>
      )
    case 'updated':
      return `changed ${event
        .fields!.map(f =>
          f === 'tags' ? 'category' : startCase(f).toLowerCase(),
        )
        .join(' and ')}`
    case 'deleted':
      return `deleted this task`
    case 'rule-matched':
      return (
        <div style={{ display: 'inline' }}>
          {onClickRule ? (
            <>
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  onClickRule()
                }}
              >
                {event.matchedRule?.title && event.matchedRule.title.length > 20
                  ? `${event.matchedRule?.title.substring(0, 20)}...`
                  : event.matchedRule?.title}
              </span>
              {event.matchedRule?.description && (
                <span style={{ marginLeft: 5 }}>
                  {event.matchedRule?.description}
                </span>
              )}
            </>
          ) : (
            <>
              <span
                style={{
                  color: 'rgb(89, 37, 220)',
                  fontWeight: 600,
                }}
              >
                {event.matchedRule?.title}
              </span>
              {event.matchedRule?.description && (
                <span style={{ marginLeft: 5 }}>
                  {event.matchedRule.description}
                </span>
              )}
            </>
          )}
        </div>
      )
    default:
      return ''
  }
}

export const humanizeActivityRelativeTime = (entry: Date) => {
  try {
    // If the entry is within the last minute (super recent), simplify the formatting.
    if (Math.abs(differenceInSeconds(new Date(), entry)) < 60) {
      return 'just now'
    }
    return `${formatDistanceToNowStrict(entry)} ago`
  } catch (ex) {
    return 'now'
  }
}

export const FileText = styled.div`
  color: ${props => props.theme.colors.text300};
  font-size: 10px;
`

export const FileHeaderText = styled.div`
  font-size: 12px;
  font-weight: bold;
  margin-top: 12px;
`

export const Dot = styled.span`
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 4px;
  background-color: ${props => props.theme.colors.text300};
  margin-right: 16px;
  margin-bottom: 0;
  margin-left: 8px;
`

export const Divider = styled.span`
  position: absolute;
  display: block;
  top: 50%;
  left: 10px;
  width: 1px;
  background-color: ${props => props.theme.colors.bg300};
  height: calc(100% + 16px);
  z-index: ${props => props.theme.zIndex.below};
`

export const EventText = styled.div`
  color: ${props => props.theme.colors.text250};
  font-size: 0.8rem;
`

const Event = ({ event }: { event: TaskActivityEvent }) => {
  const [routingState, setRoutingState] = useRecoilState(routingStateAtom)
  const { selectedContact } = useRecoilValue(authenticatedUserAtom)
  const [, setEditingRule] = useRecoilState(editingRuleAtom)
  const navigate = useNavigate()

  const isAdmin = useMemo(
    () =>
      selectedContact?.propertyInfo.some(
        propInfo => propInfo.accessLevel === AccessLevel.AdminAccess,
      ),
    [selectedContact],
  )

  const onClickRule =
    isAdmin &&
    event.matchedRule &&
    event.matchedRule.title !== 'Assign property to task'
      ? () => {
          setRoutingState({
            ...routingState,
            companySettingsTab: CompanySettingsTabs.COMMUNICATIONS,
            settingsTab: 0,
          })
          setEditingRule({
            id: event.matchedRule?.ruleId,
          } as APIRule)

          navigate('/settings')
        }
      : undefined
  return (
    <FlexRow align="flex-start" style={{ marginBottom: '1rem' }}>
      <span
        className="material-symbols-rounded"
        style={{
          fontSize: 16,
          color: '#8A94A6',
          marginRight: 8,
          paddingTop: 2,
          display: 'inline',
        }}
      >
        offline_bolt
      </span>
      <EventText>
        <div style={{ display: 'inline' }}>
          {/* <FlexRow align="center" style={{ flexWrap: 'wrap' }}> */}
          {event.event !== 'rule-matched'
            ? `${getContactDisplayName(event.by)} `
            : ''}
          {describeEvent(event, onClickRule)}.{' '}
          <Tooltip
            overlay={<span>{format(event.timestamp.toDate(), 'PPpp')}</span>}
          >
            <span style={{ fontWeight: 100, marginLeft: 8 }}>
              {' '}
              {humanizeActivityRelativeTime(event.timestamp.toDate())}
            </span>
          </Tooltip>
        </div>
        {/* </FlexRow> */}
      </EventText>
    </FlexRow>
  )
}

interface CommentProps {
  data: Comment
  index: number
  allComments?: any
  taskRef: DocumentReference<DocumentData>
  recordingUrl?: string
}

const CommentComponent = ({
  data,
  allComments,
  taskRef,
  index,
  recordingUrl,
}: CommentProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const theme = useTheme()

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }} ref={ref}>
      <div style={{ flexGrow: 1, marginRight: 12, marginBottom: 5 }}>
        <div
          className={`${`w-[fit-content] h-[100%] ${
            data.internal ? 'bg-yellow-50' : 'bg-gray-100'
          } rounded-tl-[20px] rounded-tr-[20px] rounded-bl rounded-br-[20px] flex-col justify-center items-center gap-2.5 inline-flex`}`}
        >
          <div className="self-stretch px-3 justify-center items-flex-start gap-2.5 inline-flex">
            <div className="text-sky-950 text-sm font-medium font-['Inter'] leading-tight mt-1">
              {recordingUrl && (
                <div style={{ marginTop: 10 }}>
                  <AudioPlayer audioUrl={recordingUrl} />
                </div>
              )}
              {data.text ? (
                <Editor
                  isReadonly
                  value={data.text}
                  style={{
                    height: 'unset',
                  }}
                />
              ) : (
                <div style={{ height: 12 }} />
              )}
              {recordingUrl && data.text && (
                <FlexRow style={{ marginBottom: 10, marginTop: -5 }}>
                  <HiMiniSparkles
                    style={{
                      marginRight: 4,
                      fontSize: 18,

                      color: theme.colors.secondaryPurple700,
                    }}
                  />{' '}
                  <p
                    style={{
                      color: theme.colors.secondaryPurple700,
                      fontWeight: 500,
                    }}
                  >
                    Automatically Transcribed
                  </p>
                </FlexRow>
              )}
              {data.files && data.files.length > 0 && (
                <div>
                  <FileHeaderText>Files</FileHeaderText>
                  {data.files.map((file: File) => (
                    <FileText key={file.fileURL}>
                      {file.name} {file.type} {file.size}
                    </FileText>
                  ))}
                </div>
              )}
              {data.poll && (
                <PollView
                  taskRef={taskRef}
                  comments={allComments}
                  comment={data}
                  commentIndex={index}
                  poll={data.poll}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <FlexRow align="center">
        <ContactAvatar data={data.author} style={{ marginRight: 12 }} small />
        <p className="text-center text-slate-500 text-xs font-normal font-['Inter'] leading-[18px] ml-[-5px]">
          {getContactDisplayName(data.author)}
        </p>
        <p className="px-1 text-center text-slate-500 text-xs font-normal font-['Inter'] leading-[18px]">
          ·
        </p>
        {data.internal && (
          <>
            <Tooltip overlay={<span>Visible to management and staff</span>}>
              <SubtleLink>Internal comment</SubtleLink>
            </Tooltip>
            <p className="px-1 text-center text-slate-500 text-xs font-normal font-['Inter'] leading-[18px]">
              ·
            </p>
          </>
        )}
        <Tooltip
          overlay={<span>{format(new Date(data.createdAt), 'PPpp')}</span>}
        >
          <SubtleLink>
            {humanizeActivityRelativeTime(new Date(data.createdAt))}
          </SubtleLink>
        </Tooltip>
      </FlexRow>
    </div>
  )
}

interface TaskActivityProps {
  taskRef: DocumentReference<DocumentData>
  comments: Comment[] | undefined
  rest?: any
  companyId: string
  associationId: string | null
  selectedTask?: SearchableTask
}

const TaskActivity = ({
  taskRef,
  comments,
  companyId,
  associationId,
  selectedTask,
  ...rest
}: TaskActivityProps) => {
  const firestore = useFirestore()
  const eventsCollection = collection(
    firestore,
    'companies',
    companyId,
    'companyTaskHistory',
    taskRef.id,
    'events',
  )
  const eventsQuery = query(eventsCollection, orderBy('timestamp', 'asc'))
  const { data: events } = useFirestoreCollectionData(eventsQuery)
  const allEvents = useMemo(() => {
    const result: TaskActivityCombination[] = (events || []).map(e => ({
      type: 'event',
      data: e as TaskActivityEvent,
      timestamp: e.timestamp.toDate(),
    }))

    const commentArray = comments || []

    // First add recording comment if it exists
    if (selectedTask?.recordingURL && selectedTask.assistantTriaged) {
      result.push({
        type: 'comment',
        data: {
          createdAt: selectedTask.createdAt || new Date().toISOString(),
          author: selectedTask.createdBy || {},
          internal: false,
        } as Comment,
        index: 0,
        timestamp: new Date(selectedTask.createdAt || new Date()),
      })
    }

    // Then add remaining comments with shifted indices
    commentArray.forEach((c, i) => {
      result.push({
        type: 'comment',
        data: c,
        index: selectedTask?.recordingURL ? i + 1 : i, // Shift indices if recording exists
        timestamp: new Date(c.createdAt),
      })
    })

    return result.sort((a, b) => a.timestamp.valueOf() - b.timestamp.valueOf())
  }, [events, comments, selectedTask])

  if (!allEvents) {
    return null
  }

  return (
    <div {...rest}>
      {allEvents &&
        allEvents.map((e, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div style={{ position: 'relative' }} key={`event-${index}`}>
            {e.type === 'event' && (
              <Event event={e.data as TaskActivityEvent} />
            )}
            {e.type === 'comment' && (
              <CommentComponent
                data={e.data as Comment}
                allComments={comments}
                index={e.index!}
                taskRef={taskRef}
                // optionally include the recording url if this is the first comment and the task has a recording
                recordingUrl={
                  selectedTask?.recordingURL && e.index === 0
                    ? selectedTask.recordingURL
                    : undefined
                }
              />
            )}
          </div>
        ))}
    </div>
  )
}

export default TaskActivity
